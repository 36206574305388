/*
 * @Author: 徐海瑞
 * @Date: 2021-09-18 15:18:25
 * @Last Modified by: stormlu
 * @Last Modified time: 2022-06-09 14:58:36
 * 拦截所有xhr请求
 */
import { proxy, unProxy } from 'ajax-hook';
const CryptoJS = require('crypto-js');
import {
  isCryptInterface,
  set_x_s_header,
  set_x_k_header,
  set_x_ss_req_header,
  encryptData,
  encryptFile,
  decryptData,
  decryptDataFile,
  fetchAgreement,
  resetFields,
  isJson,
  getSessionStorageItem,
  isCryptoExpired,
  CryptJsWordArrayToUint8Array,
  fileXHRResponseHandler,
} from '../utils/secret';
import { getParamByUrl } from '../utils/index';
import { toVerify } from '../core-js/config';
import CacheQData from "../utils/CacheQData";
window._xhrHook = false;
if (!window._xhrHook) {
  window._xhrHook = true;
  proxy({
    //请求发起前进入
    onRequest: async (config, handler) => {
      console.log('拦截xhr-request', config);
      if (config && config.url && !config.url.includes('/agreement') && isCryptoExpired()) {
        const fetchAgreementData = await fetchAgreement('axios');
        console.log('xhr-fetchAgreement接口返回数据', fetchAgreementData);
      }
      if (config.url && (isCryptInterface(config.url) || (config.url.indexOf('/company/imgPreview') > -1 && getParamByUrl(config.url)['imageFileId']))) {
        const isGet = config.method.toLowerCase() === 'get';

        // 请求异常时做参数上报
        const cacheQData = new CacheQData({
          url: config.url,
          headers: config.headers,
          method: config.method
        })
        config.url = config.url;
        config.headers['X-S-HEADER'] = set_x_s_header(config, cacheQData);
        config.headers['X-K-HEADER'] = set_x_k_header();
        config.headers['X-SS-REQ-HEADER'] = set_x_ss_req_header();
        if (config.url.indexOf('gate.lagou.com') == -1) {
          config.headers['X-SS-REQ-HEADER'] = set_x_ss_req_header();
        }
        // post请求加密请求体
        if (!isGet && config?.method?.toLowerCase() == 'post') {
          cacheQData.data = config.body
          // 处理post formdata 和  application/json  格式数据
          if (config.headers['content-type']?.includes('application/json') && config.body) {
            config.body = JSON.stringify({
              data: encryptData(config.body && config.body),
            });
          } else {
            let params = null;

            //form表单 分离文件和参数
            if (config?.body?.forEach) {
              let bodyFormData = new FormData();
              let fileForm = new FormData();
              let bodyParams = {};
              var paramsLen = 0;
              let hasFile = false;
              for (let [key, value] of config?.body) {
                console.log(key, value);
                paramsLen++;
                if (value instanceof File) {
                  fileForm.append(key, value);
                  hasFile = true;
                } else {
                  bodyParams[key] = value && value;
                }
              }
              if (paramsLen) {
                //文件独立加密
                if (hasFile) {
                  for (let [key, value] of fileForm) {
                    let file = await encryptFile(value);
                    bodyFormData.append(key, file);
                  }
                }
                //参数处理为对象加密后给data
                if (Object.keys.length > 0) {
                  bodyFormData.append('data', encryptData(JSON.stringify(bodyParams)));
                }
                config.body = bodyFormData;
              }
            } else {
              params = config.body;
              if (params) {
                const result = encryptData(JSON.stringify(getParamByUrl(`?${params}`)));
                config.body = `data=${encodeURIComponent(decodeURIComponent(result))}`; // +号会被浏览器当成空格,所以需要url编码
              }
            }
          }
        }
        //文件预览 参数value单独加密
        if (config.url && config.url.indexOf('/company/imgPreview') > -1 && getParamByUrl(config.url)['imageFileId']) {
          config.url = `${config.url.substr(0, config.url.indexOf('?'))}?imageFileId=${encodeURIComponent(encryptData(getParamByUrl(config.url)['imageFileId']))}`;
        }
        cacheQData?.setCacheQData?.()
      }
      handler.next(config);
    },
    //请求发生错误时进入，比如超时；注意，不包括http状态码错误，如404仍然会认为请求成功
    onError: (err, handler) => {
      try {
        let reqCacheData = CacheQData.getCacheQData(err.config?.url)
        CacheQData.handleError(reqCacheData)
      } catch (e) {}
      console.log('拦截xhr-error', err);
      handler.next(err);
    },
    //请求成功后进入
    onResponse: (response, handler) => {
      try {
        let reqCacheData = CacheQData.getCacheQData(response?.config?.url)
        if(response.status === 401) {
          CacheQData.handleError(reqCacheData)
        }
      } catch(e) {}
      let reponseHeader = response.headers['x-ss-req-header'] ? JSON.parse(response.headers['x-ss-req-header']) : {};
      console.log('reponseHeader', reponseHeader);
      const isEncrypted = response.headers['x-s-header'] || reponseHeader.encrypted;
      // 图片或者pdf文件预览
      if (response.config.url.indexOf('/company/imgPreview') > -1 || response.config.url.indexOf('/nearBy/previewResume') > -1) {
        // if (response.config.url.indexOf('/company/imgPreview') > -1) {
        response.response = fileXHRResponseHandler({ response, isEncrypted });
        handler.next(response);
        return;
      } else if (isEncrypted) {
        const data = JSON.parse(response.response);
        response.response = JSON.stringify(decryptData(data.data));

        console.log(JSON.stringify(response.response));
      }
      // if (response.config.url.indexOf('/nearBy/previewResume') > -1) {
      //   response.response = fileXHRResponseHandler({ response, isEncrypted });
      //   handler.next(response);
      //   return;
      // }
      handler.next(response);
    },
  });
}

const pages = [
  'https://easy.lagou.com/settings/account/me.htm',
  'https://easy.lagou.com/bstatus/auth/index.htm',
  'https://easy.lagou.com/im/greeting/index.htm',
  'https://easy.lagou.com/settings/account/call.htm',
  'https://easy.lagou.com/settings/account/notSuitableReply.htm',
  'https://easy.lagou.com/member/all_members.htm',
  'https://easy.lagou.com/settings/new/channel/my_channels.htm',
  'https://easy.lagou.com/settings/account/removeRecruitmentServive.htm',
  'https://easy.lagou.com/settings/account/openCopy.htm',
  'https://easy.lagou.com/',
  'https://hr.lagou.com/corpCenter/openservice/step1.html',
  'https://hr.lagou.com/corpCenter/openservice/step2.html',
  'https://hr.lagou.com/corpCenter/staff/index.html',
  'https://hr.lagou.com/corpCenter/auth/person/index.html',
  'https://passport.lagou.com/login/login.html',
  'https://passport.lagou.com/register/register.html',
  'https://passport.lagou.com/accountPwd/toReset.html',
  'https://passport.lagou.com/login/login.html',
  'https://www.lagou.com/wn/resume/myresume',
  'https://www.lagou.com/wn/recommendJob',
  'https://www.lagou.com/wn/jobs',
  'https://www.lagou.com/user/resumePrivacy.html',
  'https://www.lagou.com/gongsi/v1/',
  'https://www.lagou.com/wn/jobs/',
  'https://www.lagou.com/',
  'https://m.lagou.com/'
];

export const isCryptoPage = () => {
  const { href } = window.location;
  let isInclude = false;
  pages.forEach(item => {
    if (!isInclude) {
      isInclude = href.indexOf(item) > -1 ? true : false;
    }
  });
  return isInclude;
};

const isIE = () => {
  return window.ActiveXObject || 'ActiveXObject' in window ? !0 : !1;
};

const goTarget = b => {
  if (a()) {
    var c = document.createElement('a');
    (c.href = b), document.body.appendChild(c), c.click();
  } else location.href = b;
};
export const toVerify = t => {
  const { protocol, host, port } = (c = window.location);
  const url = protocol + '//' + host + port + '/utrack/verify.html?' + 't=' + t + '&f=' + encodeURIComponent(window.location.href);
  goTarget(url);
};

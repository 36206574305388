/*
 * @Author: 徐海瑞
 * @Date: 2021-09-18 15:19:06
 * @Last Modified by: 翟梓钦
 * @Last Modified time: 2023-06-15 12:03:35
 * 拦截所有fetch请求
 */

import {
  isCryptInterface,
  set_x_s_header,
  set_x_k_header,
  set_x_ss_req_header,
  fetchAgreement,
  encryptData,
  decryptData,
  isJson,
  isObject,
  encryptFile,
  isCryptoExpired,
  fileFetchResponseHandler, getSessionStorageItem,
} from '../utils/secret';
import fetchIntercept from 'fetch-intercept';
import { getParamByUrl } from '../utils';
import CacheQData from "../utils/CacheQData";

// 处理加密后的fetch请求,兼容response.json写法,兼容lagou-base-request包
class FetchJson {
  constructor({ data, status, ...otherProps }) {
    this._data = data || {};
    this.status = status || 200;
    for (let i in otherProps) {
      if (otherProps.hasOwnProperty(i)) {
        this[i] = otherProps[i];
      }
    }
    for (let i in data) {
      if (data.hasOwnProperty(i)) {
        this[i] = this._data[i];
      }
    }
  }
  json() {
    return Promise.resolve(this._data);
  }
  text() {
    try {
      return Promise.resolve(JSON.stringify(this._data));
    } catch (e) {
      return Promise.resolve(this._data);
    }
  }
  clone() {
    return this;
  }
}

window._fetchHook = false;
if (!window._fetchHook) {
  window._fetchHook = true;
  window.fetchRegister = fetchIntercept.register({
    request: async (url, config) => {
      // Modify the url or config here
      if (typeof url == 'object' && url instanceof Request) {
        config = {
          url: url.url,
          method: url.method,
        };
        // url  = new Request(url, config);
      } else if (config) {
        config.url = url;
      } else {
        config = { url };
      }

      console.log('拦截fetch请求-request', url, config);
      if (config.url && typeof config.url === 'string') {
        if (!config.url.includes('/agreement') && isCryptoExpired()) {
          const data = await fetchAgreement('fetch');
          console.log('fetchAgreement接口返回数据', data);
        }

        let _headers = {};
        let hasFile = false;

        if ((isCryptInterface(config.url) && !hasFile) || (config.url.indexOf('/company/imgPreview') > -1 && getParamByUrl(config.url)['imageFileId'])) {
          // 持久化参数，异常后处理上报逻辑

          config.url = config.url;

          // 请求异常时做参数上报
          const cacheQData = new CacheQData({
            url: config.url,
            headers: config.headers,
            method: config.method
          })
          _headers = {
            'X-S-HEADER': set_x_s_header(config, cacheQData),
            'X-K-HEADER': set_x_k_header(),
          };
          if (config.url.indexOf('gate.lagou.com') == -1) {
            _headers['X-SS-REQ-HEADER'] = set_x_ss_req_header();
          }
          if (config.method && config.method.toLowerCase() === 'post') {
            cacheQData.data = config.data
            // 处理post formdata 和  application/json  格式数据
            if (isJson(config.body)) {
              config.body = JSON.stringify({
                data: encryptData(config.body),
              });
            } else {
              let params = null;
              if (config?.body?.forEach) {
                let bodyFormData = new FormData();
                let fileForm = new FormData();
                let bodyParams = {};
                var paramsLen = 0;
                let hasFile = false;
                for (let [key, value] of config?.body) {
                  console.log(key, value);
                  paramsLen++;
                  if (value instanceof File) {
                    fileForm.append(key, value);
                    hasFile = true;
                  } else {
                    bodyParams[key] = value;
                  }
                }
                if (paramsLen) {
                  //文件独立加密
                  if (hasFile) {
                    for (let [key, value] of fileForm) {
                      let file = await encryptFile(value);
                      bodyFormData.append(key, file);
                    }
                  }
                  //参数处理为对象加密后给data
                  if (Object.keys.length > 0) {
                    bodyFormData.append('data', encryptData(JSON.stringify(bodyParams)));
                  }
                  config.body = bodyFormData; // +号会被浏览器当成空格,所以需要url编码
                }
              } else {
                params = config.body;
                if (params) {
                  const result = encryptData(JSON.stringify(getParamByUrl(`?${params}`)));
                  config.body = `data=${encodeURIComponent(decodeURIComponent(result))}`; // +号会被浏览器当成空格,所以需要url编码
                }
              }
            }
          }
          //todo 未调通fetch 文件预览
          if (url.indexOf && url?.indexOf?.('/company/imgPreview') > -1 && getParamByUrl(url)['imageFileId']) {
            url = `${url.substr(0, url.indexOf('?'))}?imageFileId=${encodeURIComponent(encryptData(getParamByUrl(url)['imageFileId']))}`;
          }
          cacheQData.setCacheQData()
        }
        config['headers'] = {
          ..._headers,
          ...config['headers'],
        };
      }
      return [url, config];
    },

    requestError: error => {
      // Called when an error occured during another 'request' interceptor call
      console.log('requestError', error);
      return Promise.reject(error);
    },

    response: async response => {
      // Modify the reponse object

      console.log('拦截fetch请求-response', response);
      const { status, headers, url } = response;

      let reqCacheData = CacheQData.getCacheQData(url)
      if ([1, 200].includes(status)) {
        let reponseHeader = headers.get('X-SS-REQ-HEADER') || headers.get('x-ss-req-header') ? JSON.parse(headers.get('X-SS-REQ-HEADER') || headers.get('x-ss-req-header')) : {};
        console.log('reponse-X-SS-REQ-HEADER', reponseHeader);
        const isEncrypted = headers.get('X-S-HEADER') || headers.get('x-s-header') || reponseHeader.encrypted;
        //文件预览
        if (url.indexOf('/company/imgPreview') > -1 || url.indexOf('/nearBy/previewResume') > -1) {
          // if (url.indexOf('/company/imgPreview') > -1) {
          response.response = await fileFetchResponseHandler({ response, isEncrypted });
        } else if (isEncrypted) {
          // acvitity接口response headers为 X-SS-REQ-HEADER , gate接口response headers为 X-S-HEADER
          const copyResponse = response.clone();
          return copyResponse.json().then(res => {
            let result = decryptData(res.data);
            return new FetchJson({
              data: result,
              status: copyResponse.status,
              ok: copyResponse.ok,
              responseType: 'json',
            });
          });
        }
        return response;
      } else {
        if (status === 401) {
          CacheQData.handleError(reqCacheData)
        }
        // 异常处理
        return response;
      }
    },

    responseError: error => {
      // Handle an fetch error
      try {
        let reqCacheData = CacheQData.getCacheQData(error.request.url)
        CacheQData.handleError(reqCacheData)
      } catch (e) { }
      console.log('拦截fetch请求-responseError', error);
      return Promise.reject(error);
    },
  });
  window._fetchProxy = window.fetch;
}

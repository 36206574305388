/*
 * @Author: 徐海瑞
 * @Date: 2021-09-18 11:28:39
 * @Last Modified by: 翟梓钦
 * @Last Modified time: 2023-06-15 14:20:59
 * 请求加解密
 */

const CryptoJS = require('crypto-js');
import { JSEncrypt } from '@lagou/jsencrypt'; // rsa加密库
import { isSupportAppVersion, isLgApp, getParamByUrl, blobToUnit8Array } from './index';
import { isCryptoPage } from '../core-js/config';

// window.CryptoJS = CryptoJS;
// window.JSEncrypt = JSEncrypt;
console.log('是否是测试环境', IS_DEV);

// 清空相关数据
const resetFields = () => {
  sessionStorage.removeItem('aesKey');
  sessionStorage.removeItem('rsaEncryptData');
  sessionStorage.removeItem('secretKeyValue');
  sessionStorage.removeItem('secretOpen');
  sessionStorage.removeItem('expireTime');
};
// 刷新浏览器
const refreshBrowser = url => {
  console.log('刷新浏览器', url);
  let refreshCount = Number(getSessionStorageItem('refreshCount')) || 0;
  if (isCryptInterface(url)) {
    resetFields();
    if (refreshCount < 2) {
      refreshCount += 1;
      setSessionStorageItem('refreshCount', refreshCount);
      window.location.reload();
      return;
    }
  }
};

const isCryptoExpired = () => {
  const expireTime = getSessionStorageItem('expireTime')
  const expireTimestamp = getSessionStorageItem('expireTimestamp')
  // 记录本地时间戳，比服务器端提前1分钟记录失效
  const localTimeStamp = new Date().getTime() + 1 * 60 * 1000
  return !expireTime || (expireTime && (new Date(expireTime).getTime() < localTimeStamp || expireTimestamp < localTimeStamp));
};

// 密钥协商接口
const fetchAgreement = async () => {
  return new Promise((resolve, reject) => {
    if (getSessionStorageItem('expireTime') && !isCryptoExpired()) {
      resolve(getSessionStorageItem('secretKeyValue'));
    } else {
      console.log('xxxxxxxfetchAgreementHandler');
      fetchAgreementHandler(resolve, reject);
    }
  });
};

//当请求密钥协商接口过程中，发起的获取密钥请求，缓存起来，接口回来后再调用resolve

//保证 当前只有一个密钥协商协议在发起中
window._fetchingAgreement = false;

//记录协商协商过程中 发起的页面请求
window._fetchingAgreementEvents = [];
const fetchAgreementHandler = (resolve, reject) => {
  // const url = IS_DEV ? 'http://localhost:18081/system/agreement' : 'https://gate.lagou.com/system/agreement';
  const url = 'https://gate.lagou.com/system/agreement';
  window._fetchingAgreementEvents.push({
    resolve,
    reject,
  });
  if (!window._fetchingAgreement) {
    resetFields();
    window._fetchingAgreement = true;
    const params = {
      secretKeyDecode: getSessionStorageItem('rsaEncryptData') || rsaEncrypt(),
    };
    const option = {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    };
    window._fetchProxy(url, option).then(response => {
      response.json().then(res => {
        console.log('fetch-agreement-res', res);
        setSessionStorageItem('secretKeyValue', res?.content?.secretKeyValue);
        setSessionStorageItem('secretOpen', res?.content?.secretOpen);
        setSessionStorageItem('expireTime', res?.content?.expireTime);
        setSessionStorageItem('expireTimestamp', res?.content?.expireTtl + Date.now());
        window._fetchingAgreementEvents.forEach(({ resolve, reject }) => {
          resolve(res.content.secretKeyValue);
        });
        window._fetchingAgreementEvents = [];
        window._fetchingAgreement = false;
      });
    });
  }
};

//随机生成指定位数的key
const generatekey = num => {
  let library = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  let key = '';
  for (let i = 0; i < num; i++) {
    let randomPoz = Math.floor(Math.random() * library.length);
    key += library.substring(randomPoz, randomPoz + 1);
  }
  return key;
};

// RSA加密
const rsaEncrypt = () => {
  const data = IS_DEV ? 'mF1XHOZrjUIOzoWqeVfdvZKprcK1BnfS' : generatekey(32);
  // 注意只存一次aesKey
  if (!getSessionStorageItem('aesKey')) {
    console.log('aesKey', data);
    setSessionStorageItem('aesKey', data);
  }
  // 公钥
  let publicKey =
    '-----BEGIN PUBLIC KEY-----' +
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnbJqzIX' +
    'k6qGotX5nD521Vk/24APi2qx6C+2allfix8iAfUGqx0MK3GufsQ' +
    'cAt/o7NO8W+qw4HPE+RBR6m7+3JVlKAF5LwYkiUJN1dh4sTj03X' +
    'Q0jsnd3BYVqL/gi8iC4YXJ3aU5VUsB6skROancZJAeq95p7ehXX' +
    'AJfCbLwcK+yFFeRKLvhrjZOMDvh1TsMB4exfg+h2kNUI94zu8MK' +
    '3UA7v1ANjfgopaE+cpvoulg446oKOkmigmc35lv8hh34upbMmeh' +
    'UqB51kqk9J7p8VMI3jTDBcMC21xq5XF7oM8gmqjNsYxrT9EVK7c' +
    'ezYPq7trqLX1fyWgtBtJZG7WMftKwIDAQAB' +
    '-----END PUBLIC KEY-----';
  let encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const target = encrypt.encrypt(data);
  setSessionStorageItem('rsaEncryptData', target);
  return target;
};

// 对数据使用sha
const shaText = value => {
  const hash = CryptoJS.SHA256(value).toString()?.toUpperCase();
  return hash;
};

// aes_key : 密钥 ; aes_iv: 偏移量
let aes_key = '';
const aes_iv = CryptoJS.enc.Utf8.parse('c558Gq0YQK2QUlMc');
// 加密方法
const encryptData = data => {
  aes_key = CryptoJS.enc.Utf8.parse(getSessionStorageItem('aesKey'));
  data = CryptoJS.enc.Utf8.parse(data);
  let encryptedData = CryptoJS.AES.encrypt(data, aes_key, { iv: aes_iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  console.log('加密后的x-s-header', encryptedData.toString());
  return encryptedData.toString();
};

function arrayBufferToWordArray(ab) {
  const i8a = new Uint8Array(ab);
  const a = [];
  for (let i = 0; i < i8a.length; i += 4) {
    a.push((i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3]);
  }
  return CryptoJS.lib.WordArray.create(a, i8a.length);
}
// 加密文件
const fileToWordArray = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(file); //这个读法是异步的
    reader.onloadend = res => {
      // 这个事件在读取结束后，无论成功或者失败都会触发
      if (reader.error) {
        reject('获取文件失败');
      } else {
        resolve(arrayBufferToWordArray(reader.result));
      }
    };
  });
};

/* Converts a cryptjs WordArray to native Uint8Array */
const CryptJsWordArrayToUint8Array = wordArray => {
  const l = wordArray.sigBytes;
  const words = wordArray.words;
  const result = new Uint8Array(l);
  let i = 0 /*dst*/,
    j = 0; /*src*/
  while (true) {
    // here i is a multiple of 4
    if (i == l) break;
    let w = words[j++];
    result[i++] = (w & 0xff000000) >>> 24;
    if (i == l) break;
    result[i++] = (w & 0x00ff0000) >>> 16;
    if (i == l) break;
    result[i++] = (w & 0x0000ff00) >>> 8;
    if (i == l) break;
    result[i++] = w & 0x000000ff;
  }
  return result;
};

const u8ToArrayBuffer = u8 => {
  const s = Array.prototype.map.call(u8, x => ('00' + x.toString(16)).slice(-2)).join('');

  const typedArray = new Uint8Array(
    s.match(/[\da-f]{2}/gi).map(function (h) {
      return parseInt(h, 16);
    })
  );
  return typedArray.buffer;
};

//文件加密
const encryptFile = async file => {
  aes_key = CryptoJS.enc.Utf8.parse(getSessionStorageItem('aesKey'));
  const data = await fileToWordArray(file);
  let encryptedData = CryptoJS.AES.encrypt(data, aes_key, { iv: aes_iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  console.log('加密后的x-s-header', encryptedData.toString());
  const cryptoResult = u8ToArrayBuffer(CryptJsWordArrayToUint8Array(encryptedData.ciphertext));
  const cryptoedFile = new Blob([cryptoResult], { type: file.type });
  return new File([cryptoedFile], file.name, { type: file.type });
};

// 解密方法
const decryptData = data => {
  console.log('secretData', data);
  aes_key = CryptoJS.enc.Utf8.parse(getSessionStorageItem('aesKey'));
  const ciphertext = CryptoJS.AES.decrypt(data, aes_key, { iv: aes_iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  let decryptedData = ciphertext.toString(CryptoJS.enc.Utf8);
  console.log('decryptedData', decryptedData);
  try {
    decryptedData = JSON.parse(decryptedData);
  } catch (error) {
    decryptedData = decryptedData;
  }
  return decryptedData;
};

/**
 * Converts a Uint8Array to a word array.
 *
 * @param {string} u8Str The Uint8Array.
 *
 * @return {WordArray} The word array.
 *
 */
const parse = function (u8arr) {
  // Shortcut
  const len = u8arr.length;
  // Convert
  const words = [];
  for (let i = 0; i < len; i++) {
    words[i >>> 2] |= (u8arr[i] & 0xff) << (24 - (i % 4) * 8);
  }
  return CryptoJS.lib.WordArray.create(words, len);
};

const stringify = function (wordArray) {
  // Shortcuts
  const words = wordArray.words;
  const sigBytes = wordArray.sigBytes;
  // Convert
  const u8 = new Uint8Array(sigBytes);
  for (let i = 0; i < sigBytes; i++) {
    const byte = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
    u8[i] = byte;
  }
  return u8;
};

/**
 * 解密文件
 * @param {Uint8Array} data 需要解密的文件流Uint8Array格式
 * @returns {Uint8Array} 解密后的文件流Uint8Array格式
 */
const decryptDataFile = data => {
  data = parse(data).toString(CryptoJS.enc.Base64);
  aes_key = CryptoJS.enc.Utf8.parse(getSessionStorageItem('aesKey'));
  const ciphertext = CryptoJS.AES.decrypt(data, aes_key, { iv: aes_iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return stringify(ciphertext);
};

// 设置 X-S-HEADER
const set_x_s_header = (config = {}, cacheQData) => {
  const { url = '' } = config;
  const replaceUrl = url.replace('https://gate.lagou.com', '').replace('https://activity.lagou.com', '');
  const originHeader = {
    deviceType: 1, //h5或pc请求
  };

  const value = `${JSON.stringify(originHeader)}${formatUrl(replaceUrl, false)}${formatParams(config)}`;
  console.log('加密前的x-s-header', value);
  // 对code字段进行数据摘要
  const code = shaText(value);
  console.log('加密前的x-s-header-code', code);
  if (cacheQData && isObject(cacheQData)) {
    cacheQData.beforeEncryptStr = value
    cacheQData.encryptCode = code
    cacheQData.encryptKey = getSessionStorageItem('aesKey') || 'no Key'
    cacheQData.secretKeyValue = getSessionStorageItem('secretKeyValue') || 'no Key'
  }
  // 加密数据
  return encryptData(
    JSON.stringify({
      originHeader: JSON.stringify(originHeader),
      code,
    })
  );
};

// 设置 X-K-HEADER
const set_x_k_header = () => {
  const secretKeyValue = getSessionStorageItem('secretKeyValue') || '';
  return secretKeyValue;
};

// 设置 X-SS-REQ-HEADER
const set_x_ss_req_header = () => {
  const data = JSON.stringify({
    secret: getSessionStorageItem('secretKeyValue') || '',
  });
  return data;
};

/**
 * 是否加解密
 * @param {*} method 请求方式
 * @param {*} requestUrl 请求url
 * @returns
 *
 *
 * !!! 加密的条件
 * 1. APP内, 版本为7.77.0及以上, secretOpen值为true
 * 2. 请求中带有gate.lagou.com, 密钥协商接口不用加密(/agreement)  ||  请求方式为post且不是密钥协商接口(/agreement)且不是职位海报分享
 * !!! 注意 1. get只加密请求头中X-S-HEADER,X-K-HEADER,X-SS-REQ-HEADER  2. post请求需要加密请求体
 */
const isCryptInterface = (requestUrl = '') => {
  if (requestUrl.indexOf('/') === 0) {
    const { protocol, hostname } = window.location;
    requestUrl = `${protocol}//${hostname}${requestUrl}`;
  }
  //nohost 开发不拦截
  if (requestUrl.includes('whistle') || requestUrl.includes('sockjs-node')) {
    return false;
  }
  //本地开发
  if (requestUrl.includes('localhost:18081')) {
    return true;
  }
  if (isLgApp() && isSupportAppVersion('7.77.0') && isSecretOpen()) {
    // app内
    return (
      (requestUrl.includes('gate.lagou.com') && !requestUrl.includes('/agreement')) ||
      (requestUrl.includes('activity.lagou.com') && !requestUrl.includes('sockjs-node') && !requestUrl.includes('whistle'))
    );
  } else if (!isLgApp()) {
    // 浏览器环境中加密activity
    const { href } = window.location;
    const isEasyPage = href.indexOf('easy.lagou.com') > -1 ? true : false;

    // !requestUrl.includes('/projectExperience/save.json') && ok
    // !requestUrl.includes('/workExperience/v2/save.json') && ok
    // !requestUrl.includes('/jobs/positionAjax.json') &&
    // !requestUrl.includes('/resume/intro.json') && ok
    // !requestUrl.includes('/mycenter/resume/getAllResumes.json') && ok
    // !requestUrl.includes('/template/')
    return (
      (isCryptoPage() &&
        requestUrl.includes('gate.lagou.com') &&
        !requestUrl.includes('/agreement') &&
        !requestUrl.includes('/zhaopin/positions/pages') &&
        !requestUrl.includes('/zhaopin/shopCombieCode/order/check/orderStatus')) ||
      (isCryptoPage() &&
        requestUrl.includes('https://easy.lagou.com') &&
        !/\/resume\/\d.*\.pdfa/.test(requestUrl) &&
        !/\/can\/share\/pdf\/\d.*\.pdfa/.test(requestUrl) &&
        !requestUrl.includes('/orderResume/email/nearbyPreview') &&
        !requestUrl.includes('/pub/pc/nearbyPreview.json')) ||
      (isCryptoPage() && requestUrl.includes('https://sa.lagou.com')) ||
      (isCryptoPage() && requestUrl.includes('https://www.lagou.com')) ||
      (isCryptoPage() && requestUrl.includes('https://passport.lagou.com')) ||
      (isCryptoPage() && requestUrl.includes('hr.lagou.com'))
    );
  }
  return false;
};

const encodeOrDecode = (decode, value) => {
  if (decode) {
    return window.decodeURIComponent(value)
  } else {
    return window.encodeURIComponent(window.decodeURIComponent(value))
  }
}

// url格式化
const formatUrl = (url = '', decode = true) => {
  let str = '';
  if (url.indexOf('?') > -1) {
    const arr = url
      .split('?')[1]
      .split('&')
      .filter(item => !!item);
    console.log('arr', arr);
    arr.map((item, index) => {
      if (item) {
        let splitItem = item.split('=');
        let key = splitItem[0];
        let value = item.substr(item.indexOf('=') + 1);
        if (index === 0) {
          str += `?${key}=${encodeOrDecode(decode, value)}`;
        } else {
          str += `&${key}=${encodeOrDecode(decode, value)}`;
        }
      }
    });
  }
  str = url.split('?')[0] + str;
  return str;
};
// 格式化请求参数
const formatParams = (config = {}) => {
  let target = '';
  let { method = 'get', body } = config;
  const isPost = method.toLowerCase() === 'post';
  if (isPost) {
    // 区分 content-type 是 formdata 还是 application/json
    // 如果body是json格式,则说明是application/json ,否则是formdata
    if (body) {
      body = isJson(body) ? JSON.parse(body) : getParamByUrl(`?${body}`);
      const len = Object.keys(body).length;
      // post请求参数需要序列化
      target = len ? JSON.stringify(body) : '';
    }
  }
  return target;
};

// 判断是否是json
const isJson = data => {
  try {
    let obj = JSON.parse(data);
    return !!obj && typeof obj === 'object';
  } catch (error) {
    return false;
  }
};
// 判断是否是object
const isObject = data => {
  try {
    let obj = JSON.stringify(data);
    return obj;
  } catch (error) {
    return false;
  }
};

//后端加密开关
const isSecretOpen = () => {
  return getSessionStorageItem('secretOpen') === 'true';
};

// 将某些值存到sessionStorage
const setSessionStorageItem = (key, value) => {
  sessionStorage.setItem(key, value);
};

// 获取sessionStorage中的某个字段
const getSessionStorageItem = key => {
  return sessionStorage.getItem(key);
};

const getExtension = (name = '') => {
  return name.substring(name.lastIndexOf('.') + 1);
};

const imgPreviewHandler = ({ response, isEncrypted, fileType }) => {
  return new Promise((resolve, reject) => {
    const getReader = response.body.getReader();
    let uint8 = [];
    const call = () => {
      getReader.read().then(({ done, value }) => {
        if (value?.length) {
          uint8 = uint8.concat([...value]);
          call();
        } else {
          let blob = new Blob([isEncrypted ? decryptDataFile(uint8) : uint8], { type: fileType }); // 传入一个合适的 MIME 类型
          resolve(URL.createObjectURL(blob));
        }
      });
    };
    call();
  });
};

const fileFetchResponseHandler = async ({ response, isEncrypted }) => {
  const { headers } = response;
  const contentDisposition = headers.get('Content-Disposition') || headers.get('content-disposition') || '';
  const extension = getExtension(contentDisposition);
  const fileType = `${extension == 'pdf' ? 'application' : 'image'}/${extension};charset=UTF-8`;
  const url = await imgPreviewHandler({ response, isEncrypted, fileType });
  return url;

  // const blobData = await response.blob();
  // const unit8A = await blobToUnit8Array(blobData);
  // let blob = new Blob([isEncrypted ? decryptDataFile(unit8A) : unit8A], { type: `${extension == 'pdf' ? 'application' : 'image'}/${extension};charset=UTF-8` }); // 传入一个合适的 MIME 类型
  // return URL.createObjectURL(blob);
};

const fileXHRResponseHandler = ({ response, isEncrypted }) => {
  const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition'] || '';
  const extension = getExtension(contentDisposition);
  const unit8A = new Uint8Array(response.response);
  let blob = new Blob([isEncrypted ? decryptDataFile(unit8A) : unit8A], { type: `${extension == 'pdf' ? 'application' : 'image'}/${extension};charset=UTF-8` }); // 传入一个合适的 MIME 类型
  return URL.createObjectURL(blob);
};


export {
  rsaEncrypt,
  shaText,
  encryptData,
  encryptFile,
  decryptData,
  decryptDataFile,
  set_x_s_header,
  set_x_k_header,
  set_x_ss_req_header,
  isCryptInterface,
  setSessionStorageItem,
  getSessionStorageItem,
  isSecretOpen,
  fetchAgreement,
  resetFields,
  refreshBrowser,
  isJson,
  isObject,
  isCryptoExpired,
  CryptJsWordArrayToUint8Array,
  blobToUnit8Array,
  parse,
  getExtension,
  fileFetchResponseHandler,
  fileXHRResponseHandler,
};

// 根据ua判断是否是 拉勾 App
export function isLgApp() {
  const { userAgent } = navigator;
  const reg = new RegExp(/lagou\/\S*/gi);
  return reg.test(userAgent);
}

// 获取 app version
export const getAppVersion = () => {
  const ua = navigator.userAgent;
  const [whole, appVersion] = ua.match(/Lagou\/((\d+\.)+\d+)\s+/) || [];
  return appVersion;
};

// 比较 app version
export const isAppVersionBiggerOrEqualThan = (curVersion, preVersion) => {
  const preVersionArr = preVersion.split('.') || [];
  const curVersionArr = curVersion.split('.') || [];
  const length = Math.max(preVersionArr.length, curVersionArr.length);

  for (let i = 0; i < length; ) {
    const curVersionNum = curVersionArr[i] ? Number(curVersionArr[i]) : 0;
    const preVersionNum = preVersionArr[i] ? Number(preVersionArr[i]) : 0;
    if (curVersionNum === preVersionNum) {
      i++;
    } else {
      return curVersionNum > preVersionNum;
    }
  }

  return true;
};

// 可以支持的 app version
export function isSupportAppVersion(supportVersion) {
  const appVersion = getAppVersion();
  if (!appVersion) {
    return false;
  }
  return isAppVersionBiggerOrEqualThan(appVersion, supportVersion);
}

export const getParamByUrl = (aurl, unDecode = false) => {
  let url = aurl.substr(aurl.indexOf('?')); // 获取url中"?"符后的字串
  let theRequest = new Object();
  if (url.indexOf('?') != -1) {
    let str = url.substr(1);
    let strs = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      let param = strs[i].split('=');
      if (param[1] === 'null' || param[1] === 'undefined') {
        // 若参数值不为空
        continue;
      } else {
        try {
          theRequest[param[0]] = unDecode ? param[1] : decodeURIComponent(param[1]);
        } catch (e) {
          theRequest[param[0]] = param[1];
        }
      }
    }
  }
  return theRequest;
};

export const blobToUnit8Array = async blobData => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blobData); // 这个读法是异步的
    reader.onloadend = res => {
      // 这个事件在读取结束后，无论成功或者失败都会触发
      if (reader.error) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('获取文件失败');
      } else {
        resolve(new Uint8Array(reader.result));
      }
    };
  });
};


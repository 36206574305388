/**
 * @Author : ZiQin Zhai
 * @Date : 2022/9/7 5:53 PM
 * @Version : 1.0
 * @Last Modifined by : ZiQin Zhai
 * @Last Modifined time : 2022/9/7 5:53 PM
 * @Description
 **/
import { isObject } from './secret';

const __cacheQData = {};
export default class CacheQData {
  constructor(props = {}) {
    this.url = props.url;
    this.headers = props.headers;
    this.method = props.method;
    this.beforeEncryptStr = '';
    this.encryptCode = '';
    this.encryptKey = '';
    this.data = ''
  }

  setCacheQData() {
    try {
      if (this.url) {
        __cacheQData[this.url] = this;
      }
    } catch (e) {}
  }
  static getCacheQData(key) {
    try {
      let ret = null;
      if (key) {
        ret = __cacheQData[key];
        delete __cacheQData[key];
        if (isObject(ret)) {
          ret = JSON.stringify(ret);
        }
        return ret;
      }
      return ret;
    } catch (e) {
      console.error(e)
    }
  }
  static handleError(errorMsg) {
    try {
      if (window?.elasticApmInstance?.captureError && errorMsg) {
        window.elasticApmInstance.captureError?.(new URIError(errorMsg));
      }
    } catch (e) {
      console.error(e)
    }
  }
}
